import React from "react";
import urlSlug from "url-slug";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PopupModel from "api/model/PopupModel";
import PropertyModel from "api/model/PropertyModel";
import {ProductCategory} from "api/model/ProductCategory";
import ItemTag from "components/common/ItemTag";
import PopupPriceTag from "components/Search/PopupSearch/PopupPriceTag";
import "./styles.css";

interface PopupMapInfoProps {
    popups: PopupModel[],
    property: PropertyModel,
}

interface PopupInfoProps {
    popup: PopupModel,
    property: PropertyModel,
}

const MAX_CATEGORY_COUNT = 5;

const PopupInfo = (props: PopupInfoProps) => {
    const {t} = useTranslation();
    const {popup, property} = props;
    const {requestedProductCategories} = popup;
    let categoryOverflow = 0;
    let snippedCategories: ProductCategory[] = requestedProductCategories || [];
    if (snippedCategories.length > MAX_CATEGORY_COUNT + 1) {
        categoryOverflow = snippedCategories.length - MAX_CATEGORY_COUNT;
        snippedCategories = snippedCategories.slice(0, MAX_CATEGORY_COUNT);
    }
    return (
        <div className="popup-info">
            <Link
                target="_blank"
                to={`/popup/${popup.id}/${urlSlug(`${property.name} ${popup.name}`)}`}
            >
                {
                    popup.keyPhotoUrl && (
                        <img
                            className="popup-info__image"
                            src={popup.keyPhotoUrl} alt={popup.name}
                        />
                    )
                }
                <div className="popup-info__data">
                    <div className="popup-info__title">
                        {popup.name}
                        <PopupPriceTag
                            isSmall
                            prices={popup.prices}
                        />
                    </div>
                    <div className="popup-info__description">
                        {popup.descriptionShort}
                    </div>
                    {
                        snippedCategories.length > 0 && (
                            <div className="popup-info__tags">
                                {
                                    snippedCategories.map(cat => (
                                        <ItemTag size="S" type="Success">
                                            {t(`product.category.${cat}`)}
                                        </ItemTag>
                                    ))
                                }
                                {
                                    categoryOverflow > 0 && (
                                        <ItemTag key="categories-overflow" size="S" type="Success">
                                            + {categoryOverflow}
                                        </ItemTag>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </Link>
        </div>
    );
}

const PopupMapInfo = (props: PopupMapInfoProps) => {
    const {
        popups, property
    } = props;
    return (
        <div className="popup-info-container">
            {
                popups.map(popup => <PopupInfo popup={popup} property={property}/>)
            }
        </div>
    )
}

export default PopupMapInfo;