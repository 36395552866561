import React from 'react';
import urlSlug from 'url-slug'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PlaceIcon from '@mui/icons-material/Place';
import PropertyModel from "api/model/PropertyModel";
import PopupModel from "api/model/PopupModel";
import ItemTag from "components/common/ItemTag";
import PopupPriceTag from "components/Search/PopupSearch/PopupPriceTag";
import {useLocationStore} from "store/locationStore";
import {getCountryTranslation} from "locales/static/countries";
import {SupportedLanguage} from "consts/LanguageConsts";
import {ProductCategory} from "api/model/ProductCategory";
import "./styles.css";

const MAX_CATEGORY_COUNT = 5;

interface PopupListItemProps {
    popup: PopupModel,
    property: PropertyModel,
}

const PopupListItem = (props: PopupListItemProps) => {
    const userCountryIso = useLocationStore(state => state.location?.countryIso) || 'EE';
    const {t, i18n} = useTranslation();
    const {popup, property} = props;
    const {requestedProductCategories} = popup;
    let propertyName = property.name;
    if (userCountryIso !== property.location.countryIso) {
        propertyName = `${propertyName}, ${getCountryTranslation(property.location.countryIso, i18n.language as SupportedLanguage)}`;
    }
    let categoryOverflow = 0;
    let snippedCategories: ProductCategory[] = requestedProductCategories || [];
    if (snippedCategories.length > MAX_CATEGORY_COUNT + 1) {
        categoryOverflow = snippedCategories.length - MAX_CATEGORY_COUNT;
        snippedCategories = snippedCategories.slice(0, MAX_CATEGORY_COUNT);
    }
    return (
        <Link
            target="_blank"
            to={`/popup/${popup.id}/${urlSlug(`${property.name} ${popup.name}`)}`}
            className="popup-search-list-item"
        >
            <h2 className="popup-search-list-item__property">
                <PlaceIcon color="secondary"/>
                {propertyName}
            </h2>
            <div className="popup-search-list-item__image">
                <img alt={popup.name} src={popup.keyPhotoUrl}/>
            </div>
            <div className="popup-search-list-item__content">
                <h2 className="popup-search-list-item__title">
                    {popup.name}
                </h2>
                <div className="popup-search-list-item__description">
                    {popup.descriptionShort}
                </div>
                <div className="popup-search-list-items__tags-wrapper">
                    <div className="popup-search-list-item__tags">
                        <PopupPriceTag prices={popup.prices}/>
                    </div>
                    {
                        snippedCategories.length > 0 && (
                            <div className="popup-search-list-item__tags">
                                {
                                    snippedCategories.map(cat => (
                                        <ItemTag key={cat} size="M" type="Success">
                                            {t(`product.category.${cat}`)}
                                        </ItemTag>
                                    ))
                                }
                                {
                                    categoryOverflow > 0 && (
                                        <ItemTag key="categories-overflow" size="M" type="Success">
                                            + {categoryOverflow}
                                        </ItemTag>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </Link>
    );
}

export default PopupListItem;