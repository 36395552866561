import React from "react";
import urlSlug from "url-slug";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Divider} from "@mui/material";
import EventModel from "api/model/EventModel";
import {ProductCategory} from "api/model/ProductCategory";
import ItemTag from "components/common/ItemTag";
import EventPrices from "components/Search/EventSearch/EventPrices";
import "./styles.css";

interface EventInfoProps {
    event: EventModel,
}

const MAX_CATEGORY_COUNT = 5;

const EventMapInfo = (props: EventInfoProps) => {
    const {t} = useTranslation();
    const {
        event,
    } = props;
    const {allowedCategories} = event;
    let categoryOverflow = 0;
    let snippedCategories: ProductCategory[] = allowedCategories || [];
    if (snippedCategories.length > MAX_CATEGORY_COUNT + 1) {
        categoryOverflow = snippedCategories.length - MAX_CATEGORY_COUNT;
        snippedCategories = snippedCategories.slice(0, MAX_CATEGORY_COUNT);
    }
    return (
        <div className="event-info">
            <Link
                target="_blank"
                to={`/event/${event.id}/${urlSlug(`${event.name}`)}`}
            >
                <div className="event-info__title">
                    {event.name}
                </div>
                {
                    event.keyPhoto && (
                        <div className="event-info__image">
                            <img
                                src={event.keyPhoto} alt={event.name}
                            />
                        </div>
                    )
                }
                <div className="event-info__data">
                    <div className="event-info__description">
                        {event.descriptionShort}
                    </div>
                    {
                        snippedCategories.length > 0 && (
                            <div className="event-info__tags">
                                {
                                    snippedCategories.map(cat => (
                                        <ItemTag type="Success">
                                            {t(`product.category.${cat}`)}
                                        </ItemTag>
                                    ))
                                }
                                {
                                    categoryOverflow > 0 && (
                                        <ItemTag key="categories-overflow" type="Success">
                                            + {categoryOverflow}
                                        </ItemTag>
                                    )
                                }
                            </div>
                        )
                    }
                    <br/>
                    <Divider/>
                    <EventPrices prices={event.prices}/>
                </div>
            </Link>
        </div>
    )
}

export default EventMapInfo;